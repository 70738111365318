import axios from 'axios';

const API_BASE_URL = 'https://api-intra.cuisinesetfourneaux.com/api';
//const API_BASE_URL = 'http://localhost:4000/api';

const getAuthConfig = () => {
    const JWtoken = localStorage.getItem('JWToken');
    return JWtoken ? { headers: { 'Authorization': `Bearer ${JWtoken}` } } : {};
};

const sendRequest = async (method, endpoint, data = {}) => {
    try {
        const config = getAuthConfig();
        const url = `${API_BASE_URL}${endpoint}`;
        const response = await axios({ method, url, data, ...config });
        return response;
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

const sendRequestWithoutAuth = async (method, endpoint, data = {}) => {
    try {
        const url = `${API_BASE_URL}${endpoint}`;
        const response = await axios({ method, url, data });
        return response;
    } catch (error) {
        throw error.response ? error.response.data : new Error('An error occurred');
    }
};

const api = {
    /* DB Backup */
    async getDBBackupList() {
        return sendRequest('get', '/backup/generate');
    },
    async getDBBackupItem(jsonFile) {
        return sendRequest('get', `/backup/download/${jsonFile}`);
    },
    /* Auth Methods */
    async register(user) {
        return sendRequest('post', '/users/register', user);
    },

    async login(user) {
        return sendRequestWithoutAuth('post', '/users/login', user);
    },

    async logout() {
        return sendRequest('post', '/users/logout');
    },

    /* CRUD user */
    async createUser(user) {
        return sendRequest('post', '/users', user);
    },

    async getAllUsers() {
        return sendRequest('get', '/users');
    },

    async getUserById(userId) {
        return sendRequest('get', `/users/${userId}`);
    },
    
    async updateUser(userId, user) {
        return sendRequest('put', `/users/${userId}`, user);
    },

    async deleteUser(userId) {
        return sendRequest('delete', `/users/${userId}`);
    },

    async getUserRole() {
        return sendRequest('get', '/users/role');
    },

    async getUserTeam() {
        return sendRequest('get', '/users/team');
    },

    async getUserInfo() {
        return sendRequest('get', '/users/info');
    },

    /* Price History Methods */
    async addPriceHistory(priceHistory) {
        return sendRequest('post', '/priceHistory', priceHistory);
    },

    async getPriceHistoryForAllSiteByProduct() {
        return sendRequest('get', '/priceHistory/product');
    },

    async getPriceHistoryByProduct(productId, site) {
        const endpoint = `/priceHistory/product/${productId}` + (site ? `?site=${site}` : '');
        return sendRequest('get', endpoint);
    },


    /* Product Methods */
    async addProduct(product) {
        return sendRequest('post', '/products', product);
    },

    async getAllProducts() {
        return sendRequest('get', '/products');
    },

    async getProductById(id) {
        return sendRequest('get', `/products/${id}`);
    },

    async getProductsReport(id, site) {
        const endpoint = `/products/report` + (id ? `?id=${id}` : '') + (site ? `?site=${site}` : '');
        return sendRequest('get', endpoint);
    },

    async getProductsMatch() {
        return sendRequest('get', '/products/match');
    },

    async updateProduct(id, product) {
        return sendRequest('put', `/products/${id}`, product);
    },

    async deleteProduct(id) {
        return sendRequest('delete', `/products/${id}`);
    },

    /* ScrapedSite Methods */

    async getAllScrapedSite() {
        return sendRequest('get', '/scrapedSite');
    },

    /* Team Methods */
    async addTeam(team) {
        return sendRequest('post', '/team', team);
    },

    async getAllTeams() {
        return sendRequest('get', '/team');
    },

    async getTeamById(id) {
        return sendRequest('get', `/team/${id}`);
    },

    async updateTeam(id, team) {
        return sendRequest('put', `/team/${id}`, team);
    },

    async deleteTeam(id) {
        return sendRequest('delete', `/team/${id}`);
    },


    /* Warehouse Methods */

    async addWarehouse(warehouse) {
        return sendRequest('post', '/warehouse', warehouse);
    },

    async getAllWarehouse() {
        return sendRequest('get', '/warehouse', );
    },

    async getWarehouseById(id) {
        return sendRequest('get', `/warehouse/${id}`);
    },

    async getAllProductByWarehouse(id) {
        return sendRequest('get', `/warehouse/${id}/product`);
    },

    async updateWarehouse(id, warehouse) {
        return sendRequest('put', `/warehouse/${id}`, warehouse);
    },

    async addTeamToWarehouse(id, teamId) {
        return sendRequest('put', `/warehouse/${id}/team/${teamId}`);
    },

    async deleteTeamToWarehouse(id, teamId) {
        return sendRequest('delete', `/warehouse/${id}/team/${teamId}`);
    },

    async deleteWarehouse(id) {
        return sendRequest('delete', `/warehouse/${id}`);
    },

    /* StockProduct Methods */

    async sendCsvOpenSi(csv) {
        return sendRequest('post', `/stockProduct/csv`, csv);
    },

    async searchStockProduct(stockProduct) {
        return sendRequest('put', `/stockProduct/searchproduct`, stockProduct);
    },

    async addStockProduct(stockProduct) {
        return sendRequest('post', '/stockProduct', stockProduct);
    },

    async updateStockProduct(id, stockProduct) {
        return sendRequest('put', `/stockProduct/${id}`, stockProduct);
    },

    async correctionStockProduct(id, stockProduct) {
        return sendRequest('put', `/stockProduct/correction/${id}`, stockProduct);
    },

    /* StockHistory Methods */

    async getAllStockHistoryByTeamByWarehouse(teamID, warehouseID) {
        return sendRequest('get', `/stockHistory/warehouse/${warehouseID}/team/${teamID}`);
    },

    /* StockHistory Methods */

    async getAllStockError() {
        return sendRequest('get', `/stockErrors`);
    },

    async getStockErrorById(id) {
        return sendRequest('get', `/stockErrors/${id}`);
    },

    async updateStockErrorState(id, errorState) {
        return sendRequest('put', `/stockErrors/${id}`, errorState);
    },

    async genStockError() {
        return sendRequest('get', `/stockErrors/process`);
    },

    async genCSV() {
        return sendRequest('get', `/stockErrors/generate-csv`)
    },

    async genManuelCSV() {
        return sendRequest('get', `/stockErrors/generate-manual-csv`)
    },

    /* SupportTicket Methods */
    async createSupportTicket(ticket) {
        return sendRequest('post', '/supportTicket', ticket);
    },
    async getAllSupportTicket() {
        return sendRequest('get', '/supportTicket');
    },

    async getSupportTicketById(id) {
        return sendRequest('get', `/supportTicket/${id}`);
    },

    async updateSupportTicketById(id, ticket) {
        return sendRequest('put', `/supportTicket/${id}`, ticket);
    },

    async deleteSupportTicketById(id) {
        return sendRequest('delete', `/supportTicket/${id}`);
    },

    /* SupportTag Methods */
    async createSupportTag(tag) {
        return sendRequest('post', '/supportTag', tag);
    },

    async getAllSupportTag() {
        return sendRequest('get', '/supportTag');
    },

    async getSupportTagById(id) {
        return sendRequest('get', `/supportTag/${id}`);
    },

    async updateSupportTagById(id, tag) {
        return sendRequest('put', `/supportTag/${id}`, tag);
    },

    async deleteSupportTagById(id) {
        return sendRequest('delete', `/supportTag/${id}`);
    },

    /* DailyReport */

    async generateDailyReport() {
        return sendRequest('get', '/dailyReports/gen');
    },

    async getLastestDailyReport() {
        return sendRequest('get', '/dailyReports/latest');
    },

    async getDailyReportById(id) {
        return sendRequest('get', `/dailyReports/${id}`);
    },

    async getFilteredAllDailyReport({ month, day, year } = {}) {
        let endpoint = '/dailyReports?';
        const params = { month, day, year };
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined) {
                endpoint += `${key}=${value}&`;
            }
        }
        console.log(endpoint);
        return sendRequest('get', endpoint);
    },

    async getGraphDataDailyReportNbProduct() {
        return sendRequest('get', '/dailyReports/graph-data');
    },

    
    
    /* Product Report Methods */

    async generateProductReports() {
        return sendRequest('get', `/productReports/gen`);
    },
    
    async getCSVProductReport() {
        return sendRequest('get', '/productReports/gen-csv');
    },

    async getProductReportById(id) {
        return sendRequest('get', `/productReports/${id}`);
    },

    async getFilteredProductReport({ name, page, size, lesscost, maxdiff, maxdiffpourcent, discount, rate, id } = {}) {
        let endpoint = '/productReports/?';
        let body = {};
        if (id) {
            body = { id };
        } else
            body = {};
        const params = { name, page, size, lesscost, maxdiff, maxdiffpourcent, discount, rate };
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined) {
                endpoint += `${key}=${value}&`;
            }
        }
        console.log(endpoint);
        return sendRequest('post', endpoint, body);
    },

    /* SMS Methods */

    async createSMS(sms) {
        return sendRequest('post', '/sms', sms);
    },
    async getAllSMS() {
        return sendRequest('get', '/sms');
    },
    async getSMSById(id) {
        return sendRequest('get', `/sms/${id}`);
    },
    async updateSMSById(id, sms) {
        return sendRequest('put', `/sms/${id}`, sms);
    },
    async deleteSMSById(id) {
        return sendRequest('delete', `/sms/${id}`);
    },

    /* Orders Methods */

    async getOrdersByIds(ids) {
        const endpoint = `/orders/${ids.join(',')}`;
        return sendRequest('get', endpoint);
    },
    /* mettre ici les bon arguments et crée la route back si nécessaire*/
    // async getFilteredOrder({ name, page, size, lesscost, maxdiff, maxdiffpourcent, discount, rate, id } = {}) {
    //     let endpoint = '/orders/?';
    //     let body = {};
    //     if (id) {
    //         body = { id };
    //     } else
    //         body = {};
    //     const params = { name, page, size, lesscost, maxdiff, maxdiffpourcent, discount, rate };
    //     for (const [key, value] of Object.entries(params)) {
    //         if (value !== undefined) {
    //             endpoint += `${key}=${value}&`;
    //         }
    //     }
    //     console.log(endpoint);
    //     return sendRequest('post', endpoint, body);
    // },

    /* Customer Methods */

    async getAllCustomers() {
        return sendRequest('get', '/customers');
    },
    async getCustomerById(id) {
        return sendRequest('get', `/customers/${id}`);
    },
    async updateCustomerById(id, customer) {
        return sendRequest('put', `/customers/${id}`, customer);
    },

    /* mettre ici les bon arguments et crée la route back si nécessaire*/
    async getFilteredCustomer({ firstname, lastname, email, phone, prestashopIds, originSite, orderReference, size, page, id } = {}) {
        let endpoint = '/customers/filter?';
        let body = {};
        if (id) {
            body = { id };
        } else
            body = {};
        const params = { firstname, lastname, email, phone, prestashopIds, originSite, orderReference, size, page };
        for (const [key, value] of Object.entries(params)) {
            if (value !== undefined) {
                endpoint += `${key}=${value}&`;
            }
        }
        console.log(endpoint);
        return sendRequest('post', endpoint, body);
    },
    

    // ... ici, vous pouvez continuer avec d'autres méthodes si nécessaire
};

export default api;

